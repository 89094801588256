import React, { useState } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

const RecruiterView = props => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  console.log(props)
  return (
    <div className="page-content">
      <table style={{ minHeight: "25vh" }}>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Status</th>
        </tr>
        <tr>
          <td>{props.history.location.state.id.name}</td>
          <td>{props.history.location.state.id.email}</td>
          <td>
            <Dropdown
              isOpen={dropdownOpen}
              toggle={() => setDropdownOpen(!dropdownOpen)}
            >
              <DropdownToggle className="btn btn-secondary" caret>
                Status <i className="mdi mdi-chevron-down" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>Completed</DropdownItem>
                <DropdownItem>In progress</DropdownItem>
                <DropdownItem>Rejected</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </td>
        </tr>
      </table>
    </div>
  )
}

export default RecruiterView
