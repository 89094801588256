import React, { useState } from "react"
import { Row, Col, CardBody, Card, Container } from "reactstrap"
import { Form, ErrorMessage, Field, Formik } from "formik"
import "./Style.css"
import { Link } from "react-router-dom"

import { postData } from "../Api/ApiRequest"
// import images
import profile from "../../assets/images/profile-img.png"
import Loader from "pages/Loader/Loader"

const Login = () => {
  const [error, setError] = useState(null)
  const [clicked, setClicked] = useState(false)
  const [loading, setLoading] = useState(true)
  const [response, setResponse] = useState("")

  const handleSubmit = async data => {
    setClicked(true)
    let resData

    console.log("resData", data)
    try {
      resData = await postData("/auth/forget-password", data)
      setError(null)

      setLoading(false)

      console.log(resData)
      setResponse(resData.data.message)
    } catch (err) {
      setError(err.response.data.message)
      console.log(err.response)
      setLoading(false)
    }
  }
  function validate(values) {
    const errors = {}
    if (!values.email) {
      errors.email = "Required"
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address"
    }
    if (!values.password) {
      errors.password = "Required"
    }
    if (!values.verificationCode) {
      errors.code = "Required"
    }
    return errors
  }
  return (
    <div>
      <Formik
        initialValues={{ email: "", password: "", verificationCode: "" }}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {() => (
          <>
            <div className="home-btn d-none d-sm-block">
              <Link to="/" className="text-dark">
                <i className="fas fa-home h2" />
              </Link>
            </div>
            <div className="account-pages my-5 pt-sm-5">
              <Container>
                <Row className="justify-content-center">
                  <Col md={8} lg={7} xl={5}>
                    <Card className="overflow-hidden">
                      <div className="card-bg">
                        <Row>
                          <Col xs={8}>
                            <div className="p-4">
                              <h5 className="text">Welcome Back !</h5>
                              <p>Reset your password.</p>
                            </div>
                          </Col>
                          <Col className="col-4 align-self-end">
                            <img src={profile} alt="" className="img-fluid" />
                          </Col>
                        </Row>
                      </div>
                      <CardBody className="pt-0">
                        <div className="p-2">
                          <br />
                          <Form className="form-horizontal">
                            <div className="mb-3">
                              <Field
                                name="email"
                                type="email"
                                className="form-control select"
                                placeholder="Enter email"
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                style={{ color: "red" }}
                              />
                            </div>

                            <div className="mb-3">
                              <Field
                                name="password"
                                type="password"
                                className="form-control select"
                                placeholder="Enter Password"
                              />
                              <ErrorMessage
                                name="password"
                                component="div"
                                style={{ color: "red" }}
                              />
                            </div>
                            <div className="mb-3">
                              <Field
                                name="verificationCode"
                                type="number"
                                className="form-control select"
                                placeholder="Enter Activation code"
                              />
                              <ErrorMessage
                                name="code"
                                component="div"
                                style={{ color: "red" }}
                              />
                            </div>

                            <div className="mt-3 d-grid button-color">
                              <button
                                className="btn btn-block waves-effect waves-light"
                                type="submit"
                                style={{ color: "white" }}
                              >
                                {clicked && loading ? (
                                  <Loader />
                                ) : (
                                  "Reset Password"
                                )}
                              </button>
                            </div>
                            <p style={{ color: "red" }}>{error}</p>
                            <p style={{ color: "red" }}>{response}</p>

                            <div className="mt-4 text-center">
                              <Link to="/forgotPassword" className="text-muted">
                                <i className="mdi mdi-lock me-1" />
                                Forgot your password?
                              </Link>
                            </div>
                          </Form>
                        </div>
                      </CardBody>
                    </Card>
                    <div className="mt-5 text-center">
                      <p>
                        Don&#39;t have an account ?
                        <Link to="register" className="fw-medium text-primary">
                          Signup now
                        </Link>
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </>
        )}
      </Formik>
    </div>
  )
}
export default Login
